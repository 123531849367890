var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"px-4"},[_c('h2',{staticClass:"mt-2"},[_vm._v(" Sessions de rendez-vous ")]),_c('p',{staticClass:"mt-2"},[_vm._v(" Visualisez et organisez ici les sessions de rendez-vous d'accompagnement de vos acquéreurs. ")]),_c('v-card',{staticClass:"d-flex align-center pa-2"},[_c('v-text-field',{staticClass:"flex-grow-0 mr-2",attrs:{"label":"Recherche","color":"app-blue","prepend-inner-icon":"fas fa-search","outlined":"","hide-details":"","dense":""},on:{"keyup":function($event){return _vm.doSearch()}},model:{value:(_vm.search.quickSearch),callback:function ($$v) {_vm.$set(_vm.search, "quickSearch", $$v)},expression:"search.quickSearch"}}),_c('v-select',{staticClass:"flex-grow-0 mr-2",attrs:{"items":['Choix et options','Visite cloisons','Visite pré-livraison','Livraison et remise des clés'],"label":"Objet","outlined":"","hide-details":"","dense":"","menu-props":{ bottom: true, offsetY: true },"multiple":""},on:{"change":function($event){return _vm.doSearch()}},model:{value:(_vm.search.subjects),callback:function ($$v) {_vm.$set(_vm.search, "subjects", $$v)},expression:"search.subjects"}}),_c('ProgramAutocomplete',{ref:"programAutocomplete",staticClass:"flex-grow-0 mr-2",attrs:{"label":"Programmes","multiple":""},on:{"input":function($event){return _vm.doSearch()}},model:{value:(_vm.search.programs),callback:function ($$v) {_vm.$set(_vm.search, "programs", $$v)},expression:"search.programs"}}),_c('UserAutocomplete',{ref:"userAutocomplete",staticClass:"flex-grow-0 mr-2",attrs:{"label":"Intervenants","multiple":""},on:{"input":function($event){return _vm.doSearch()}},model:{value:(_vm.search.intervenants),callback:function ($$v) {_vm.$set(_vm.search, "intervenants", $$v)},expression:"search.intervenants"}}),_c('CreateAppointmentSessionDialog',{on:{"created":function($event){return _vm.fetchAppointments()}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var dialog = ref.on;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-2",attrs:{"color":"primary","small":""}},Object.assign({}, dialog, tooltip)),[_vm._v(" Nouvelle session ")])]}}],null,true)},[_vm._v(" Créer une nouvelle session ")])]}}])}),_c('v-spacer'),_c('v-select',{staticClass:"sort-pagination d-inline-flex flex-grow-0",attrs:{"label":"Trier par","menu-props":{ bottom: true, offsetY: true },"items":_vm.sortList,"background-color":"white","hide-details":"","outlined":"","dense":""},on:{"change":function($event){return _vm.fetchAppointments(true)}},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-2",attrs:{"icon":""},on:{"click":function($event){return _vm.toggleOrder()}}},on),[_c('v-icon',{class:{'fa-rotate-180': _vm.by === 'asc'}},[_vm._v(" fas "+_vm._s(_vm.by === 'asc' ? 'fa-sort-amount-down-alt': 'fa-sort-amount-down')+" ")])],1)]}}])},[(_vm.by === 'asc')?[_vm._v(" Croissant ")]:_vm._e(),(_vm.by === 'desc')?[_vm._v(" Décroissant ")]:_vm._e()],2)]},proxy:true}]),model:{value:(_vm.order),callback:function ($$v) {_vm.order=$$v},expression:"order"}}),_c('v-pagination',{attrs:{"length":_vm.totalPages,"total-visible":"5","color":"app-blue"},on:{"input":function($event){return _vm.fetchAppointments(true, false)}},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1),_c('v-simple-table',{staticClass:"rounded elevation-2 my-4"},[[_c('thead',[_c('tr',[_c('th',[_vm._v(" Objet ")]),_c('th',[_vm._v(" Programme ")]),_c('th',[_vm._v(" Intervenant ")]),_c('th',[_vm._v(" Période ")]),_c('th',[_vm._v(" Acquéreurs ")])])]),_c('tbody',_vm._l((_vm.appointments),function(appointment){return _c('tr',{key:appointment.id},[_c('td',[_vm._v(" "+_vm._s(appointment.subject)+" ")]),_c('td',[(appointment.programs.length > 0)?_c('span',[_vm._v(" "+_vm._s(appointment.programs[0].title)+" ")]):_vm._e()]),_c('td',[(appointment.intervenants.length > 0)?_c('span',[_c('ManageAppointmentSessionPlanningDialog',{attrs:{"appointmentId":appointment.id},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var dialog = ref.on;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-chip',_vm._g({staticClass:"mr-1",attrs:{"label":"","small":""}},Object.assign({}, tooltip, dialog)),[_c('v-icon',[_vm._v(" fas fa-calendar ")])],1)]}}],null,true)},[_vm._v(" Cliquez pour afficher et gérer le planning de l'intervenant ")])]}}],null,true)}),_vm._v(" "+_vm._s(appointment.intervenants[0].name)+" "+_vm._s(appointment.intervenants[0].firstname)+" ")],1):_vm._e()]),_c('td',[_vm._v(" du "+_vm._s(_vm._f("toDay")(appointment.minDate))+" "+_vm._s(_vm._f("toDate")(appointment.minDate))+" "),_c('br'),_vm._v(" au "+_vm._s(_vm._f("toDay")(appointment.maxDate))+" "+_vm._s(_vm._f("toDate")(appointment.maxDate))+" ")]),_c('td',[_c('ManageAppointmentSessionPlanningDialog',{attrs:{"appointmentId":appointment.id},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var dialog = ref.on;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-chip',_vm._g({staticClass:"mr-1",attrs:{"label":"","small":""}},Object.assign({}, tooltip, dialog)),[_c('v-icon',{attrs:{"left":""}},[_vm._v(" fas fa-users ")]),_vm._v(" "+_vm._s(appointment.buyers.length)+" ")],1)]}}],null,true)},[_vm._v(" Session de rendez-vous proposée à "+_vm._s(appointment.instances.length)+" acquéreurs "),_c('br'),_vm._v(" Cliquez pour visualiser et sélectionner les acquéreurs concernés ")])]}}],null,true)}),_c('ManageAppointmentSessionPlanningDialog',{attrs:{"appointmentId":appointment.id},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var dialog = ref.on;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-chip',_vm._g({staticClass:"mr-1",attrs:{"label":"","small":""}},Object.assign({}, tooltip, dialog)),[_c('v-icon',{attrs:{"left":""}},[_vm._v(" fas fa-calendar ")]),_vm._v(" "+_vm._s(appointment.instances.length)+" ")],1)]}}],null,true)},[_vm._v(" "+_vm._s(appointment.instances.length)+" acquéreurs ont planifié leur rendez-vous "),_c('br'),_vm._v(" Cliquez pour afficher le planning des rendez-vous à honorer ")])]}}],null,true)})],1)])}),0)]],2),(_vm.totalPages > 1)?_c('v-card',{staticClass:"d-flex align-center pa-2"},[_c('v-spacer'),_c('v-pagination',{attrs:{"length":_vm.totalPages,"total-visible":"5","color":"app-blue"},on:{"input":function($event){return _vm.fetchAppointments(true, false)}},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }