<template>
    <v-dialog v-model="isOpen" width="900px">
        <template v-slot:activator="{ on }">
            <slot name="activator" v-bind="{ on }" />
        </template>

        <v-card>
            <v-toolbar dense flat>
                <v-toolbar-title class="title primary--text">
                    Gestion des intervenants
                </v-toolbar-title>

                <v-spacer />

                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn @click="isOpen = false" color="primary" icon v-on="on">
                            <v-icon> far fa-times-circle </v-icon>
                        </v-btn>
                    </template>
                    Fermer la fenêtre
                </v-tooltip>
            </v-toolbar>

            <v-divider class="mb-4" />

            <v-card-text>
                <UserAutocomplete ref="userAutocomplete" v-model="selectedIntervenant" label="Intervenant" returnObject />

                <v-simple-table>
                    <thead>
                        <tr>
                            <th>

                            </th>

                            <th>
                                
                            </th>

                            <th class="text-right">
                                Peut uniquement consulter <br> les messages du programme
                            </th>

                            <th>

                            </th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr v-for="intervenant of intervenants" :key="intervenant.id">
                            <td>
                                <v-icon color="app-blue"> fas fa-user</v-icon>
                            </td>

                            <td>
                                <span class="font-weight-bold">
                                    {{ intervenant.name }}
                                    {{ intervenant.firstname }}
                                </span>

                                <template v-if="intervenant.job">
                                    - <span class="grey--text"> {{ intervenant.job }} </span>
                                </template> <br>
                                {{ intervenant.email }}
                            </td>

                            <td>
                                <v-checkbox v-model="intervenant.canSeeProgramMessages" class="float-right" />
                            </td>

                            <td>
                                <v-btn @click="removeIntervenant(intervenant)" icon small>
                                <v-icon color="red" small> fas fa-times </v-icon>
                            </v-btn>
                            </td>
                        </tr>
                    </tbody>
                </v-simple-table>

                <h2 v-if="intervenants.length === 0" class="text-center">
                    Aucun intervenant selectionné
                </h2>
            </v-card-text>

            <v-divider />

            <v-card-actions>
                <v-btn @click="submit()" color="primary" small> Enregistrer </v-btn>

                <v-spacer />

                <v-btn @click="isOpen = false" small> Annuler </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import UserAutocomplete from '../../widgets/UserAutocomplete.vue';

export default {
    name: 'ManageProgramIntervenants',

    components: {
        UserAutocomplete
    },

    props: {
        programId: { type: Number, required: true }
    },

    data: () => ({
        isOpen: false,
        program: null,
        intervenants: [],
        selectedIntervenant: null
    }),

    watch: {
        isOpen() {
            if (this.isOpen) {
                this.fetchProgram();
            }
        },

        selectedIntervenant() {
            if (this.selectedIntervenant) {
                const existing = this.intervenants.find((i) => i.id === this.selectedIntervenant.id);
                if (!existing) {
                    this.intervenants.push(this.selectedIntervenant);
                }
                this.$nextTick(() => {
                    this.selectedIntervenant = null;
                });
            }
        }
    },

    methods: {
        removeIntervenant(intervenant) {
            this.intervenants = this.intervenants.filter((i) => i.id !== intervenant.id);
        },

        async fetchProgram() {
            try {
                this.setLoading(true);

                const query = {
                    page: 1,
                    limit: 1,
                    programs: [this.programId],
                    include: 'intervenants'
                };

                const { programs, err } = await this.repos.programs.getPrograms(query);
                if (err) {
                    throw new Error(err);
                }
                this.program = programs[0];
                this.intervenants = this.program.intervenants;
            } catch (err) {
                console.error(err);
                this.$notify({
                    title: 'Erreur',
                    text: 'Une erreur est survenue lors du chargement des intervenants',
                    type: 'error'
                });
            } finally {
                this.setLoading(false);
            }
        },

        async submit() {
            try {
                this.setLoading(true);

                const intervenants = this.intervenants.map((i) => ({
                    id: i.id,
                    canSeeProgramMessages: i.canSeeProgramMessages
                }));

                const body = { intervenants };
                const { success, err } = await this.repos.programs.updateIntervenants(this.programId, body);
                if (success) {
                    this.isOpen = false;
                    this.$emit('updated');
                } else {
                    throw new Error(err);
                }
            } catch (err) {
                console.error(err);
                this.$notify({
                    title: 'Erreur',
                    text: 'Une erreur est survenue lors de la mise à jour des intervenants',
                    type: 'error'
                });
            } finally {
                this.setLoading(false);
            }
        }
    }
};
</script>