<template>
    <v-autocomplete ref="autocomplete" v-model="selectedContacts" :items="contacts" :loading="isLoading" :multiple="multiple" item-value="email" :search-input.sync="search" :menu-props="{ bottom: true, offsetY: true }" @change="$emit('input', selectedContacts)" :disabled="disabled" label="Contact" :no-data-text="noDataText" background-color="white" hide-details cache-items no-filter outlined clearable dense>
        <template v-slot:selection="data">
            <v-chip v-bind="data.attrs" :input-value="data.selected" @click="data.select" label small>
                <v-avatar class="blue white--text text-uppercase" left style="height: 16px">
                    {{ data.item.name.slice(0, 1) }}
                </v-avatar>
                <span v-if="data.item.name || data.item.name">
                    {{ data.item.name }} {{ data.item.firstname }}
                </span>
                <span v-else>
                    <i> Sans nom </i>
                </span>
            </v-chip>
        </template>

        <template v-slot:item="{ item }">
            <v-list-item-avatar class="blue white--text text-uppercase headline">
                {{ item.name.slice(0, 1) }}
            </v-list-item-avatar>

            <v-list-item-content>
                <v-list-item-title>
                    <span v-if="item.name || item.name">
                        {{ item.name }} {{ item.firstname }}
                    </span>
                    <span v-else>
                        <i> Sans nom </i>
                    </span>
                </v-list-item-title>

                <v-list-item-subtitle>
                    <span v-if="item.email">
                        {{ item.email }}
                    </span>
                    <span v-else>
                        <i> Sans email </i>
                    </span>
                    -
                    <span v-if="item.mobile">
                        {{ item.mobile }}
                    </span>
                    <span v-else>
                        <i> Sans portable </i>
                    </span>
                </v-list-item-subtitle>
            </v-list-item-content>
        </template>
    </v-autocomplete>
</template>

<script>
export default {
    name: 'ContactAutocomplete',

    props: {
        value: {},
        disabled: { type: Boolean, default: false },
        multiple: { type: Boolean, default: false },
        initPartnerContactId: { type: Number },
        initProgramsFilter: { type: Array }
    },

    data: () => ({
        selectedContacts: null,
        contacts: [],
        isLoading: false,
        search: null,
        searchTimeout: null,
        isTyping: false
    }),

    watch: {
        search(search) {
            this.isTyping = true;
            clearTimeout(this.searchTimeout);
            this.searchTimeout = setTimeout(() => {
                if (search !== '') {
                    this.makeSearch(search);
                }
                this.isTyping = false;
            }, 500);
        }
    },

    methods: {
        async makeSearch(search) {
            if (typeof search !== 'string' || search.length.length < 3) {
                return;
            }
            if (this.isLoading) {
                return;
            }
            const query = {
                limit: 5,
                quickSearch: search
            };
            this.fetchContacts(query);
        },

        async fetchContacts(query) {
            try {
                this.isLoading = true;

                if (this.initProgramsFilter) {
                    query.programs = this.initProgramsFilter.join(',');
                }

                const { contacts } = await this.repos.contacts.getContacts(query);
                this.contacts = contacts;
                this.$nextTick(() => {
                    this.$refs.autocomplete.cachedItems = this.$refs.autocomplete.cachedItems.filter((item) => this.selectedContacts.includes(item.email));
                });
            } catch (err) {
                console.error(err);
            } finally {
                this.isLoading = false;
            }
        },

        denonciationCreated({ visitorId, partnerContactId }) {
            this.selectedContacts = visitorId;
            this.fetchContacts({ partnerContactIds: [partnerContactId] });
        },

        reset() {
            this.selectedContacts = [];
        }
    },

    computed: {
        noDataText() {
            if (this.isLoading || this.isTyping) {
                return 'Chargement...';
            } else if (this.search) {
                return 'Aucun résultat';
            } else {
                return 'Commencez à taper pour lancer une recherche';
            }
        }
    },

    created() {
        this.selectedContacts = this.value;

        if (this.initPartnerContactId) {
            this.fetchContacts({ partnerContactIds: [this.initPartnerContactId] });
        } else if ((Array.isArray(this.selectedContacts) && this.selectedContacts.length > 0) || typeof this.selectedContacts === 'number') {
            const contacts = Array.isArray(this.selectedContacts) ? this.selectedContacts : [this.selectedContacts];
            this.fetchContacts({ emails: contacts });
        }
    }
};
</script>