var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"900px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_vm._t("activator",null,null,{ on: on })]}}],null,true),model:{value:(_vm.isOpen),callback:function ($$v) {_vm.isOpen=$$v},expression:"isOpen"}},[_c('v-card',[_c('v-toolbar',{attrs:{"dense":"","flat":""}},[_c('v-toolbar-title',{staticClass:"title primary--text"},[_vm._v(" Planifier une nouvelle session de rendez-vous ")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"primary","icon":""},on:{"click":function($event){_vm.isOpen = false}}},on),[_c('v-icon',[_vm._v(" far fa-times-circle ")])],1)]}}])},[_vm._v(" Fermer la fenêtre ")])],1),_c('v-divider',{staticClass:"mb-4"}),_c('v-card-text',[_c('p',[_vm._v(" Saisissez ici le contexte complet des rendez-vous à organiser (programme, type de rendez-vous, acquéreurs, intervenants, planning, ...). ")]),_c('ValidationObserver',{ref:"observer"},[_c('table',{staticStyle:{"border-spacing":"10px","width":"100%"}},[_c('tr',[_c('td',[_vm._v(" Programme "),_c('span',{staticClass:"red--text"},[_vm._v(" * ")])]),_c('td',{attrs:{"colspan":"2"}},[_c('ValidationProvider',{attrs:{"name":"Objet","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failed = ref.failed;
return [_c('ProgramAutocomplete',{ref:"programAutocomplete",attrs:{"label":"Program","errors":errors,"failed":failed,"outlined":"","dense":""},model:{value:(_vm.appointment.programId),callback:function ($$v) {_vm.$set(_vm.appointment, "programId", $$v)},expression:"appointment.programId"}})]}}])})],1)]),_c('tr',[_c('td',[_vm._v(" Objet "),_c('span',{staticClass:"red--text"},[_vm._v(" * ")])]),_c('td',{attrs:{"colspan":"2"}},[_c('ValidationProvider',{attrs:{"name":"Objet","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failed = ref.failed;
return [_c('v-select',{attrs:{"items":_vm.subjectItems,"label":"Objet","menu-props":{ bottom: true, offsetY: true },"error-messages":errors,"hide-details":!failed,"outlined":"","dense":""},model:{value:(_vm.appointment.subject),callback:function ($$v) {_vm.$set(_vm.appointment, "subject", $$v)},expression:"appointment.subject"}})]}}])})],1)]),_c('tr',[_c('td',[_vm._v(" Durée estimée "),_c('span',{staticClass:"red--text"},[_vm._v("*")])]),_c('td',{attrs:{"colspan":"2"}},[_c('ValidationProvider',{attrs:{"name":"Durée estimée","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failed = ref.failed;
return [_c('v-select',{attrs:{"items":_vm.durationItems,"label":"Durée estimée","menu-props":{ bottom: true, offsetY: true },"error-messages":errors,"hide-details":!failed,"outlined":"","dense":""},model:{value:(_vm.appointment.duration),callback:function ($$v) {_vm.$set(_vm.appointment, "duration", $$v)},expression:"appointment.duration"}})]}}])})],1)]),_c('tr',[_c('td',[_c('span',[_vm._v(" Plage de dates proposée "),_c('span',{staticClass:"red--text"},[_vm._v("*")])])]),_c('td',[_c('ValidationProvider',{attrs:{"name":"De","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failed = ref.failed;
return [_c('DatePickerMenu',{attrs:{"classes":"flex-grow-0","label":"De","errors":errors,"failed":failed},model:{value:(_vm.appointment.minDate),callback:function ($$v) {_vm.$set(_vm.appointment, "minDate", $$v)},expression:"appointment.minDate"}})]}}])})],1),_c('td',[_c('ValidationProvider',{attrs:{"name":"A","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failed = ref.failed;
return [_c('DatePickerMenu',{attrs:{"classes":"flex-grow-0","label":"A","errors":errors,"failed":failed},model:{value:(_vm.appointment.maxDate),callback:function ($$v) {_vm.$set(_vm.appointment, "maxDate", $$v)},expression:"appointment.maxDate"}})]}}])})],1)]),_c('tr',[_c('td',[_vm._v(" Lieu ")]),_c('td',{attrs:{"colspan":"2"}},[_c('v-text-field',{attrs:{"label":"Lieu","hide-details":"","outlined":"","dense":""},model:{value:(_vm.appointment.location),callback:function ($$v) {_vm.$set(_vm.appointment, "location", $$v)},expression:"appointment.location"}})],1)]),_c('tr',[_c('td',[_vm._v(" Intervenant "),_c('span',{staticClass:"red--text"},[_vm._v(" * ")])]),_c('td',{attrs:{"colspan":"2"}},[_c('ValidationProvider',{attrs:{"name":"Objet","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failed = ref.failed;
return [_c('UserAutocomplete',{ref:"userAutocomplete",attrs:{"label":"Intervenant","errors":errors,"failed":failed,"outlined":"","dense":""},model:{value:(_vm.appointment.intervenantId),callback:function ($$v) {_vm.$set(_vm.appointment, "intervenantId", $$v)},expression:"appointment.intervenantId"}})]}}])})],1)])])])],1),_c('v-divider'),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"primary","small":""},on:{"click":function($event){return _vm.submit()}}},[_vm._v(" Enregistrer ")]),_c('v-spacer'),_c('v-btn',{attrs:{"small":""},on:{"click":function($event){_vm.isOpen = false}}},[_vm._v(" Annuler ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }