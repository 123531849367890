<template>
    <tr>
        <td>
            <v-tooltip v-if="instance" top>
                <template v-slot:activator="{ on }">
                    <v-icon :color="getAppointmentStatusColor(instance.status)" class="mr-4" x-small v-on="on">
                        fas fa-calendar
                    </v-icon>
                </template>
                {{ getAppointmentStatusLabel(instance.status) }}
            </v-tooltip>
        </td>

        <td>
            {{ buyer.name }} {{ buyer.firstname }}
        </td>

        <td>
            <ConfirmationDialog v-if="!instance" @confirm="removeBuyer(buyer.email)" text="Voulez-vous vraiment retirer cet acquéreur de la session de rendez-vous ?">
                <template v-slot:activator="{ on: tooltip }">
                    <v-tooltip top>
                        <template v-slot:activator="{ on: dialog }">
                            <v-btn class="ml-2" color="red" x-small text icon v-on="{ ...dialog, ...tooltip }">
                                <v-icon x-small> fas fa-times </v-icon>
                            </v-btn>
                        </template>
                        Supprimer
                    </v-tooltip>
                </template>
            </ConfirmationDialog>
        </td>
    </tr>
</template>

<script>
import appointmentMixin from '../../mixins/appointments.js';
import ConfirmationDialog from '../dialogs/ConfirmationDialog';

export default {
    name: 'ManageAppointmentSessionPlanningDialog',

    mixins: [appointmentMixin],

    components: {
        ConfirmationDialog
    },

    props: {
        appointment: { type: Object, required: true },
        buyer: { type: Object, required: true },
        instance: { type: Object }
    },

    data: () => ({
       
    })
};
</script>
