import Vue from 'vue';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';

Vue.filter('toDate', (str) => {
    let date = '--/--/----';
    try {
        if (str) {
            date = format(new Date(str), 'dd/MM/yyyy', { locale: fr });
        }
    } catch (err) {
        console.error(err);
    }
    return date;
});

Vue.filter('toTime', (str, seconds = true) => {
    let date = '--:--:--';
    try {
        if (str) {
            date = format(new Date(str), seconds ? 'HH:mm:ss' : 'HH:mm', { locale: fr });
        }
    } catch (err) {
        console.error(err);
    }
    return date;
});

Vue.filter('toDatetime', (str, seconds = true) => {
    let date = '--/--/---- --:--:--';
    try {
        if (str) {
            date = format(new Date(str), seconds ? 'dd/MM/yyyy HH:mm:ss' : 'dd/MM/yyyy HH:mm', { locale: fr });
        }
    } catch (err) {
        console.error(err);
    }
    return date;
});

Vue.filter('toMonthYearDate', (str) => {
    let date = '';
    try {
        if (str) {
            date = format(new Date(str), 'MMMM yyyy', { locale: fr });
        }
    } catch (err) {
        console.error(err);
    }
    return date;
});

Vue.filter('toDayMonthYearDate', (str) => {
    let date = '';
    try {
        if (str) {
            date = format(new Date(str), 'dd MMMM yyyy', { locale: fr });
        }
    } catch (err) {
        console.error(err);
    }
    return date;
});

Vue.filter('toDateDatabase', (str) => {
    let date = '----/--/--';
    try {
        if (str) {
            date = format(new Date(str), 'yyyy-MM-dd', { locale: fr });
        }
    } catch (err) {
        console.error(err);
    }
    return date;
});

Vue.filter('toDayNumber', (str) => {
    let date = '--/--/----';
    try {
        if (str) {
            date = format(new Date(str), 'dd', { locale: fr });
        }
    } catch (err) {
        console.error(err);
    }
    return date;
});

Vue.filter('toDay', (str) => {
    let date = '--/--/----';
    try {
        if (str) {
            date = format(new Date(str), 'EEEE', { locale: fr });
        }
    } catch (err) {
        console.error(err);
    }
    return date;
});

Vue.filter('toMonth', (str) => {
    let date = '--/--/----';
    try {
        if (str) {
            date = format(new Date(str), 'MMMM', { locale: fr });
        }
    } catch (err) {
        console.error(err);
    }
    return date;
});

Vue.filter('toDayMonth', (str) => {
    let date = '--/--/----';
    try {
        if (str) {
            date = format(new Date(str), 'EEEE dd MMMM', { locale: fr });
        }
    } catch (err) {
        console.error(err);
    }
    return date;
});

Vue.filter('minutesToHours', (minutes) => {
    const hours = Math.floor(minutes / 60);
    const minutesLeft = minutes % 60;
    if (minutesLeft === 0) {
        return `${hours}h`;
    } else if (hours === 0) {
        return `${minutesLeft} minutes`;
    } else {
        return `${hours}h${minutesLeft}`;
    }
});
