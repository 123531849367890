<template>
    <v-menu v-model="isOpen" offset-y :close-on-content-click="false">
        <template v-slot:activator="{ on }">
            <slot name="activator" v-bind="{ on }" />
        </template>

        <v-card class="pa-2">
            <DatePickerMenu v-model="day" label="Jour" />

            <div class="mt-2 d-flex">
                <HoursSelect v-model="startHour" label="De" style="max-width: 112px;" />

                <v-spacer />
                
                <HoursSelect v-model="endHour" label="A" style="max-width: 112px;"  />
            </div>

            <v-btn @click="submit()" class="mt-2" color="primary" small>
                Créer
            </v-btn>
        </v-card>
    </v-menu>
</template>

<script>
import HoursSelect from '../widgets/HoursSelect';
import DatePickerMenu from '../widgets/DatePickerMenu.vue';

export default {
    name: 'CreateSingleDisponibilityMenu',

    components: {
        HoursSelect,
        DatePickerMenu
    },

    props: {
        userId: { type: Number, required: true }
    },

    data: () => ({
        isOpen: false,

        day: null,
        startHour: null,
        endHour: null
    }),

    methods: {
        async submit() {
            try {
                this.setLoading(true);

                const startDate = this.$options.filters.toDateDatabase(this.day) + ' ' + this.startHour;
                const endDate = this.$options.filters.toDateDatabase(this.day) + ' ' + this.endHour;

                const body = {
                    disponibility: {
                        userId: this.userId,
                        startDate,
                        endDate
                    }
                };

                const { success, err } = await this.repos.appointments.createDisponibility(body);
                if (success) {
                    this.isOpen = false;
                    this.$emit('created');
                    this.$notify({
                        title: 'Information',
                        text: 'La disponibilité a bien été créée',
                        type: 'success'
                    });
                } else {
                    throw new Error(err);
                }
            } catch (err) {
                console.error(err);
                this.$notify({
                    title: 'Erreur',
                    text: 'Une erreur est survenue lors de la modification d\'une disponibilité',
                    type: 'error'
                });
            } finally {
                this.setLoading(false);
            }
        }
    }
};
</script>
