<template>
    <v-menu v-model="isOpen" offset-y :close-on-content-click="false">
        <template v-slot:activator="{ on }">
            <slot name="activator" v-bind="{ on }" />
        </template>

        <v-card class="pa-2">
            <ContactAutocomplete v-model="newBuyer" ref="contactAutocomplete" :initProgramsFilter="appointment.programIds" />

            <v-btn @click="submit()" class="mt-2" color="primary" small>
                Ajouter
            </v-btn>
        </v-card>
    </v-menu>
</template>

<script>
import ContactAutocomplete from '../widgets/ContactAutocomplete.vue';

export default {
    name: 'AddBuyerMenu',

    components: {
        ContactAutocomplete
    },

    props: {
        appointment: { type: Object, required: true }
    },

    data: () => ({
        isOpen: false,

        newBuyer: null
    }),

    methods: {
        async submit() {
            try {
                this.setLoading(true);

                const body = {
                    appointment: {
                        buyers: [...this.appointment.buyersIds, this.newBuyer]
                    }
                };

                const { success, err } = await this.repos.appointments.updateAppointment(this.appointment.id, body);
                if (success) {
                    this.isOpen = false;
                    this.$emit('created');
                    this.$notify({
                        title: 'Information',
                        text: 'Le nouvel acquéreur a bien été ajouté',
                        type: 'success'
                    });
                } else {
                    throw new Error(err);
                }
            } catch (err) {
                console.error(err);
                this.$notify({
                    title: 'Erreur',
                    text: 'Une erreur est survenue lors de l\'ajout du nouvel acquéreur',
                    type: 'error'
                });
            } finally {
                this.setLoading(false);
            }
        }
    },

    watch: {
        isOpen() {
            if (this.isOpen) {
                this.$nextTick(() => {
                    this.$refs.contactAutocomplete.fetchContacts({ limit: 10000 });    
                });
            }
        }
    }
};
</script>
